
// Brown
@font-face {
  font-family: 'Brown';
  src: url('../public/assets/fonts/brown/Brown-Regular.ttf');
}

// Poppins - new font

@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: url('../public/assets/fonts/poppins/Poppins-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'BoiFont';
  src: url('../public/assets/fonts/boi/BankofIrelandSerif-Bd.ttf');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url('../public/assets/fonts/boi/OpenSans.ttf');
  font-weight: 400;
  font-style: normal;
}
// Neue Haas Grotesk Display Pro <- Old Font

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-BlackItalic.ttf');
//   font-weight: 900;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-Mediu.ttf');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-Light.ttf');
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-ThinItalic.ttf');
//   font-weight: 100;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-RomanItalic.ttf');
//   font-weight: 400;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-XXThinItalic.ttf');
//   font-weight: 100;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-Roman.ttf');
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-BoldItalic.ttf');
//   font-weight: bold;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-Thin.ttf');
//   font-weight: 100;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-Black.ttf');
//   font-weight: 900;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-XXThin.ttf');
//   font-weight: 100;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-MediumItalic.ttf');
//   font-weight: 500;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-Bold.ttf');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-XThin.ttf');
//   font-weight: 100;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-LightItalic.ttf');
//   font-weight: 300;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Neue Haas Grotesk Display Pro';
//   src: url('../public/assets/fonts/neue-haas-grotesk/NeueHaasDisplay-XThinItalic.ttf');
//   font-weight: 100;
//   font-style: italic;
// }