
@import './colors.scss';
@import './fonts.scss';
@import './constants.scss';
@import './rich.text.scss';
@import './toast.scss';
@import './themes.scss';

:root {
  --main-font: 'Poppins', sans-serif;
}

html, body {
  overflow-x: hidden;
  padding-top: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  min-height: 100vh;
}

h1 {
  font-weight: 400;
}
h2, h3 {
  font-weight: 500;
}
p, input, textarea, select, option, li {
  font-weight: 300;
}

input, textarea {
  caret-color: var(--caret-color);
}

svg {
  width: 100%;
  height: 100%;
  font-family: 'Brown', sans-serif;

  * {
    transition: fill .2s;
  }

  g {
    text {
      tspan {
        font-family: inherit;
      }
    }
  }
}

b {
  font-weight: 500;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: var(--main-font);
  color: $black-color;
  margin: 0;
}

.nevo-logo {
  width: 120px;
  height: 57px;

  @media screen and (max-width: $small-screen) {
    width: 107px;
    height: 51px;
  }
}

@keyframes appearAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.semiBold {
  font-weight: 500 !important;
}
.white {
  color: $white-color;
}
.blue {
  color: $blue-color !important;
}
.mega-blue {
  color: $blue-color !important;
  font-weight: 500 !important;
}

.boi-blue {
  color: $nevo-boi-dark-blue-color !important;
}

.huge-header {
  font-size: 70px;
  line-height: 90px;
  @media screen and (max-width: $small-screen) {
    font-size: 60px;
    line-height: 75px;
  }
  @media screen and (max-width: 900px) {
    font-size: 44px;
    line-height: 56px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 30px;
    line-height: 45px;
  }
}

.big-header {
  font-size: 38px;
  line-height: 50px;

  @media screen and (max-width: $small-screen) {
    font-size: 29.2px;
    line-height: 36px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 24px;
    line-height: 32px;
  }
}

.big-text {
  font-size: 22px;
  line-height: 36px;
  @media screen and (max-width: $small-screen) {
    font-size: 15.7px;
    line-height: 26px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 14px;
    line-height: 22px; 
  }
}

.page {
  overflow-x: hidden;
  animation: appearAnimation .8s ease-in-out;

  position: relative;
  z-index: 1;
}

.padding-top {
  padding-top: 135px; // 75px without banner, 135px with banner

  @media screen and (max-width: $small-screen) {
    padding-top: 110px; // 60px without banner, 110px with banner
  }
}

.ad-banner-post {
  margin-top: 65px;

  @media screen and (max-width: $tablet-screen) {
    margin-top: 40px;
  }
}

.page-holder {
  overflow-x: hidden;
  animation: appearAnimation .8s ease-in-out;

  position: relative;
  z-index: 1;

  margin-top: 135px; // 75px without banner, 135px with banner
  padding-bottom: 100px;
  
  min-height: calc(100vh - 619px);

  @media screen and (max-width: $small-screen) {
    min-height: calc(100vh - 604px);
    margin-top: 110px; // 60px without banner, 110px with banner
  }

  .component:first-child {
    padding-top: 100px;

    @media screen and (max-width: $tablet-screen) {
      padding-top: 40px;
    }
  }

  .component {
    padding-top: 60px;
    padding-bottom: 60px;

    @media screen and (max-width: $tablet-screen) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.page-holder-boi {
  overflow-x: hidden;
  animation: appearAnimation .8s ease-in-out;

  position: relative;
  z-index: 1;

  margin-top: 250px; // 75px without banner, 135px with banner
  padding-bottom: 100px;
  
  min-height: calc(100vh - 619px);

  @media screen and (max-width: $small-screen) {
    min-height: calc(100vh - 604px);
    margin-top: 220px; // 60px without banner, 110px with banner
  }

  .component:first-child {
    padding-top: 100px;

    @media screen and (max-width: $tablet-screen) {
      padding-top: 40px;
    }
  }

  .component {
    padding-top: 60px;
    padding-bottom: 60px;

    @media screen and (max-width: $tablet-screen) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.all-content-page {
  margin-top: 75px;
  padding-top: 100px;
  padding-bottom: 100px;

  padding-left: 14vw;
  padding-right: 14vw;

  min-height: calc(100vh - 619px);

  @media screen and (max-width: $small-screen) {
    min-height: calc(100vh - 604px);
    margin-top: 60px;
    padding-left: 10%;
    padding-right: 10%;
  }
  @media screen and (max-width: $tablet-screen) {
    padding-top: 40px;
  }
  @media screen and (max-width: $mobile-screen) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.all-content-page-boi {
  margin-top: 75px;
  padding-top: 100px;
  padding-bottom: 100px;

  padding-left: 14vw;
  padding-right: 14vw;

  min-height: calc(100vh - 619px);
  background-color: $nevo-boi-light-color;

  @media screen and (max-width: $small-screen) {
    min-height: calc(100vh - 604px);
    margin-top: 60px;
    padding-left: 10%;
    padding-right: 10%;
  }
  @media screen and (max-width: $tablet-screen) {
    padding-top: 40px;
  }
  @media screen and (max-width: $mobile-screen) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.padding {
  padding-left: 14%;
  padding-right: 14%;
  
  @media screen and (max-width: $small-screen) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media screen and (max-width: $mobile-screen) {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.mini-padding {
  padding-left: 5%;
  padding-right: 5%;
}

.underline {
  text-decoration: underline;
}

.posts-preview-page {
  margin-top: 75px;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 14vw;

  min-height: calc(100vh - 619px);

  @media screen and (max-width: $small-screen) {
    margin-top: 60px;
    min-height: calc(100vh - 604px);
    padding-left: 10%;
  }
  @media screen and (max-width: $tablet-screen) {
    padding-top: 40px;
  }
  @media screen and (max-width: $mobile-screen) {
    padding-left: 5%;
  }
}

.header {
  font-size: 36px;
  font-weight: 500;
}

.nav-button {
  user-select: none;
  cursor: pointer;

  font-weight: 500;
  font-size: 20px;
  
  @media screen and (max-width: $small-screen) {
    font-size: 17px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 12px;
  }
}

.default-cursor {
  cursor: default !important;
}

.button-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .spinner-holder {
    animation: appear .8s ease-out;
  }
}

.blue-button, .button-holder {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  
  cursor: pointer;

  border: 3px solid $border-blue-color;
  border-radius: 100px;
  background-color: transparent;
  
  color: $black-color;
  
  width: 100%;
  height: 40px;
  
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;

  transition: background-color .1s ease-in, border .1s ease-in, color .1s ease-in;

  &:hover {
    color: $white-color;
    border: 3px solid $blue-hover-color;
    background-color: $blue-hover-color;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.ask-a-question-button, .button-holder {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  
  cursor: pointer;

  border: 3px solid $border-blue-color;
  border-radius: 100px !important;
  background-color: transparent;
  
  color: $white-color;
  
  width: 100%;
  height: 40px;
  
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;

  transition: background-color .1s ease-in, border .1s ease-in, color .1s ease-in;

  &:hover {
    color: $white-color;
    border: 3px solid $blue-hover-color;
    background-color: $blue-hover-color;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.dark-blue-button-boi {
  cursor: pointer;

  width: 100%;
  height: 50px;

  font-size: 14px;
  line-height: 40px;
  font-weight: 400;

  transition: background-color .1s ease-in, border .1s ease-in, color .1s ease-in;

  border-radius: 8px !important;
  color: $white-color !important;
  background-color: $nevo-boi-blue-color;
  border-color: $nevo-boi-blue-color;
  border-bottom: 5px solid $nevo-boi-dark-blue-color;

  &:hover {
    color: $white-color;
    background-color: $nevo-boi-dark-button-main-color;
    border-color: $nevo-boi-dark-button-main-color;
    border-bottom: 5px solid $nevo-boi-dark-button-shadow-color;
  }
}

.light-blue-button-boi {
  cursor: pointer;

  width: 100%;
  height: 50px;

  font-size: 14px;
  line-height: 40px;
  font-weight: 400;

  transition: background-color .1s ease-in, border .1s ease-in, color .1s ease-in;
  
  border-radius: 8px !important;
  color: $nevo-boi-blue-color !important;
  border: 2px solid $nevo-boi-blue-color;
  border-color: $nevo-boi-blue-color;
  background-color: $white-color;
  border-bottom: 5px solid $nevo-boi-blue-color !important;

  &:hover {
    color: $nevo-boi-dark-button-shadow-color !important;
    border-bottom: 5px solid $nevo-boi-dark-button-shadow-color !important;
    border-color: $nevo-boi-dark-button-shadow-color !important;
    background-color: $nevo-boi-light-color;
  }
}

.videoNavFilterButton {
  cursor: pointer;

  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 18px;
  line-height: 51px;

  font-size: 14px;
  line-height: 40px;
  font-weight: 400;

  transition: background-color .1s ease-in, border .1s ease-in, color .1s ease-in;

  border-radius: 8px !important;
  color: $nevo-boi-blue-color !important;
  border: 2px solid $nevo-boi-blue-color;
  border-color: $nevo-boi-blue-color;
  background-color: $white-color;
  border-bottom: 5px solid $nevo-boi-blue-color !important;

  &:hover {
    border-radius: 8px !important;
    color: $white-color !important;
    background-color: $nevo-boi-blue-color;
    border-color: $nevo-boi-blue-color;
    border-bottom: 5px solid $nevo-boi-dark-blue-color;
  }

}

.videoNavFilterButton-active {
  border-radius: 8px !important;
  color: $white-color !important;
  background-color: $nevo-boi-blue-color;
  border-color: $nevo-boi-blue-color;
  border-bottom: 5px solid $nevo-boi-dark-blue-color;
}

.action-button {
  min-width: 180px;
  width: 180px;
  height: 50px;

  background: $blue-color;
  border: none !important;
  border-radius: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  font-size: 18px;
  color: $white-color;
  font-weight: 500;

  @media screen and (max-width: 980px) {
    min-width: 150px;
    width: 150px;
  }
  @media screen and (max-width: $mobile-screen) {
    min-width: 130px;
    width: 130px;
    height: 45px;
    font-size: 14px;
  }

  span {
    margin-right: 10px;
    font-weight: inherit !important;
    
    font-size: inherit !important;
    color: inherit !important;

    @media screen and (max-width: $mobile-screen) {
      margin-right: 5px;
    }
  }

  svg {
    width: 12.5px;
    height: unset;

    @media screen and (max-width: $mobile-screen) {
      width: 10px
    }
  }
}
.action-button:hover {
  background: $blue-hover-color !important;
}

.action-button-boi {
  min-width: 180px;
  width: 180px;
  height: 50px;

  background: $white-color;
  border-bottom: 5px solid $nevo-boi-blue-color;
  border-radius: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  font-size: 18px;
  color: $nevo-boi-blue-color;
  font-weight: 500;

  @media screen and (max-width: 980px) {
    min-width: 150px;
    width: 150px;
  }
  @media screen and (max-width: $mobile-screen) {
    min-width: 130px;
    width: 130px;
    height: 45px;
    font-size: 14px;
  }

  span {
    margin-right: 10px;
    font-weight: inherit !important;
    
    font-size: inherit !important;
    color: inherit !important;

    @media screen and (max-width: $mobile-screen) {
      margin-right: 5px;
    }
  }

  svg {
    width: 12.5px;
    height: unset;

    @media screen and (max-width: $mobile-screen) {
      width: 10px
    }
  }
}

.action-button-boi:hover {
  color: $nevo-boi-dark-blue-color !important;
  border-color: $nevo-boi-dark-blue-color !important;
  border-bottom: 5px solid $nevo-boi-dark-blue-color !important;
}

.grey-button {
  display: block;
  width: 180px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  line-height: 51px;
  border: none !important;

  background: $light-grey-color !important;
  color: $grey-color !important;

  @media screen and (max-width: $small-screen) {
    width: 150px;
    height: 44px;
    text-align: center;
    font-size: 16px;
    line-height: 46px;
  }

  &:hover, &:active {
    background: $grey-color !important;
    color: $white-color !important;
  }
}

// Always used in pair with blue-button styling
.load-more-button-boi {
  display: block;

  width: 200px;
  height: 50px;
  border-radius:8px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;

  color: $white-color;

  @media screen and (max-width: $mobile-screen) {
    margin-left: auto;
    margin-right: auto;
  }
}

.load-more-button {
  display: block;

  margin-top: 106px;
  width: 200px;
  height: 50px;
  border-radius:25px;
  font-weight: 400;
  font-size: 18px;

  background-color: $blue-color;
  color: $white-color;

  @media screen and (max-width: $mobile-screen) {
    margin-left: auto;
    margin-right: auto;
  }
}

.input-holder, .transparent-input {
  div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    background-color: $light-grey-color;
    border-radius: 0;
  
    input, textarea {
      width: 100%;
      outline: none;
      border: none;
      border-radius: 0;

      background-color: inherit;
      color: $black-color;

      margin-top: 2px;
      padding: 11px 25px;

      @media screen and (max-width: $mobile-screen) {
        padding: 11px 15px;
      }
    }

    textarea {
      min-height: 200px;
    }

    input::placeholder, textarea::placeholder {
      color: $grey-color;
    }

    .readonly-input {
      text-overflow: ellipsis;
      overflow: hidden; 
      white-space: nowrap;
    }
  }
}

.transparent-input {
  div {
    background-color: unset;
    border: 1px solid rgba($color: $grey-color, $alpha: 0.5);
    transition: border .2s ease-out;

    input, textarea {
      font-size: 16px;

      @media screen and (max-width: $mobile-screen) {
        font-size: 14px;
      }
    }

  }

  div:hover {
    border-color: $black-color;
  }
}

.horizontal-slider {
  margin-top: 37px !important;
  margin-bottom: 0 !important;
  height: 3px !important;
  border-radius: 5px !important;
  background-color: $light-grey-color !important;
  box-shadow: none !important;

  .rangeslider__fill {
    background-color: $blue-color !important;
    box-shadow: none !important;
  }

  .rangeslider__handle {
    width: 18px !important;
    height: 18px !important;
    border-radius: 100% !important;
    background-color: $blue-color !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }
  .rangeslider__handle:after {
    visibility: hidden;
  }
}

.horizontal-sliderBoi {
  margin-top: 37px !important;
  margin-bottom: 0 !important;
  height: 3px !important;
  border-radius: 5px !important;
  background-color: $nevo-boi-lightest-grey-color !important;
  box-shadow: none !important;

  .rangeslider__fill {
    background-color: $nevo-boi-blue-color !important;
    box-shadow: none !important;
  }

  .rangeslider__handle {
    width: 18px !important;
    height: 18px !important;
    border-radius: 100% !important;
    background-color: $nevo-boi-blue-color !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }
  .rangeslider__handle:after {
    visibility: hidden;
  }
}

.lease-text {
  font-size: 32px;
  color: $white-color;
  text-align: center;

  @media screen and (max-width: $small-screen) {
    font-size: 24px;
  }
  @media screen and (max-width: $mobile-screen) {
    font-size: 20px;  
  }
}

.post-page-holder {
  padding-bottom: 0 !important;
}

.post-type {
  font-size: 28px;
  margin-bottom: 7px;

  &.boi {
    color: $nevo-boi-blue-color;
  }

  &.nevo {
    color: $blue-color;
  }

  @media screen and (max-width: $small-screen) {
    font-size: 22px;
  }
}
.post-holder {
  width: 80%;
  max-width: 800px;
  padding-top: 65px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: $tablet-screen) {
    padding-top: 40px;
  }
  @media screen and (max-width: $mobile-screen) {
    width: unset;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  
  .post-title {
    font-weight: 500;
    font-size: 62px;
    line-height: 82px;

    @media screen and (max-width: $small-screen) {
      font-size: 40px;
      line-height: 60px;
    }
  }
  .post-meta {
    margin-top: 30px;
    font-size: 16px;
    line-height: 26px;
    
    color: $grey-color;

    @media screen and (max-width: $small-screen) {
      margin-top: 18px;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .news-source {
    margin-top: 50px;
    font-size: 14px;

    a {
      color: $link-color;
      text-decoration: underline;
    }
  }

  .preview-image {
    margin-top: 50px;
    width: 100%;

    img {
      width: 100%;
    }

    @media screen and (max-width: $small-screen) {
      margin-top: 40px;
    }
  
    @media screen and (max-width: $mobile-screen) {
      width: 100vw;
      position: relative;
      left: -5vw;
    }
  }
}

.learn-post-holder {
  padding-top: 150px;
  max-width: 1120px;

  @media screen and (max-width: $small-screen) {
    padding-top: 60px;
  }

  .post-title {
    font-size: 70px;
    line-height: 106px;

    @media screen and (max-width: $small-screen) {
      font-size: 56px;
      line-height: 84px;
    }

    @media screen and (max-width: $mobile-screen) {
      font-size: 40px;
      line-height: 60px;
    }
  }

  .post-meta {
    margin-top: 10px;
    font-size: 24px;
    line-height: 39px;

    @media screen and (max-width: $small-screen) {
      font-size: 18px;
      line-height: 30px;
    }
  }

  .post-tags {
    margin-top: 33px;

    @media screen and (max-width: $small-screen) {
      margin-top: 28px;
    }
  }

  .preview-image { 
    margin-top: 58px;

    width: 100vw;
    max-width: 1200px;
    
    position: relative;
    left: -40px;

    @media screen and (max-width: $small-screen) {
      margin-top: 48px;
    }

    @media screen and (max-width: 1400px) {
      max-width: 100%;
      left: 0;
    }

    @media screen and (max-width: 1200px) {
      width: 100vw;
      max-width: unset;
      position: relative;
      left: -10vw;
    }
    
    @media screen and (max-width: $mobile-screen) {
      left: -5vw;
    }
  }

  .post-video {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 855px;
    max-width: 100%;

    @media screen and (max-width: $mobile-screen) {
      width: 100vw;
      max-width: unset;
      position: relative;
      left: -5vw;
    }
  }
}

.youtube-video {
  width: 100%;
  height: unset;
  aspect-ratio: 16/9;
  background-color: $background-grey-color;
}

.blurry-cover {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;

  width: 100vw;
  height: 100vh;

  background-color: rgba($color: $black-color, $alpha: 0.8);
}

.fading-cover {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
}

.cover {
  position: fixed;
  background-color: $light-grey-color;
}

.copyright-mark {
  font-size: 15px;
  color: $grey-color;
  font-weight: 300;

  @media screen and (max-width: $small-screen) {
    font-size: 14px;
  }
}

.invisible-element {
  opacity: 0;
  a {
    cursor: initial;
  }
}

.small-text {
  font-size: 17px;

  @media screen and (max-width: $small-screen) {
    font-size: 13px;
  }
}

.ad-banner {
  margin-top: 80px;
  @media screen and (max-width: $small-screen) {
    margin-top: 25px;
  }
  @media screen and (max-width: $mobile-screen) {
    margin-top: unset;
  }
}

.ad-banner-bottom {
  margin-bottom: 75px;
  @media screen and (max-width: $small-screen) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: $tablet-screen) {
    margin-bottom: 40px;   
  }
}

.side-ad-banner {
  margin-top: 20px;
}

.ad-between-vehicles {
  grid-column: span 3; 
  @media screen and (max-width: $small-screen) {
    grid-column: span 2;  
  }
  @media screen and (max-width: 460px) {
    grid-column: span 1;
  }
}