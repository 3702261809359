@import "./colors.scss";
@import "./constants.scss";

.ql-size-huge, .ql-size-large {
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}

@media screen and (max-width: $mobile-screen) {
  .ql-size-huge, .ql-size-large { 
    font-size: 20px;
    line-height: 24px;
  }
}

.first-item  {
  margin-top: 0 !important;
}

.grey-div {
  margin-top: 88px !important;

  padding: 36px 40px 48px;
  background: $light-grey-color;
  border-radius: 7px;
  border: 1px solid $new-border-grey;

p, .small-margin {
  margin-top: 17px !important;
}

  p {
    font-size: 20px !important;
    line-height: 40px !important;

    span {
      display: inline !important;
    }
  }

  .big-margin-top {
    margin-top: 70px !important;
  }

  .small-p {
    margin-top: 60px !important;
    color: $weird-grey;

    font-size: 16px !important;
    line-height: 26px !important;
  }

  @media screen and (max-width: $small-screen) {
    margin-top: 60px !important;
    padding: 28px 30px 32px;

    p, .small-margin {
      margin-top: 12px !important;
    }

    p {
      font-size: 15.5px !important;
      line-height: 26px !important;
    }

    .big-margin-top {
      margin-top: 60px !important;
    }

    .small-p {
      margin-top: 44px !important;
      font-size: 12px !important;
      line-height: 22px !important;
    }
  }
  @media screen and (max-width: $mobile-screen) {
    margin-top: 50px !important;
    padding: 22px 20px 24px;

    p, .small-margin {
      margin-top: 8px !important;
    }

    p {
      font-size: 14px !important;
      line-height: 24px !important;
    }

    .big-margin-top {
      margin-top: 55px !important;
    }
  }
}

.image-margin-top {
  margin-top: 107px !important;

  @media screen and (max-width: $small-screen) {
    margin-top: 84px !important;
  }

  @media screen and (max-width: $mobile-screen) {
    margin-top: 62px !important;
  }
}